import { FC, useCallback } from 'react';

import { IKdsApiDisplay } from '~/modules/board/interfaces/IKdsApiDisplay';

import { Container, DisplayOption } from './styles';

interface IDisplaySelectionModalProps {
  readonly displays: IKdsApiDisplay[];
  readonly current: string;
  readonly onConfirm: (display: IKdsApiDisplay) => void;
}
export const DisplaySelectionModal: FC<IDisplaySelectionModalProps> = ({
  displays,
  current,
  onConfirm,
}) => {
  const renderDisplayOption = useCallback(
    (display: IKdsApiDisplay) => {
      const { _id, environments, name } = display;
      const environmentsNames = environments.map((env) => env.name);

      return (
        <DisplayOption
          onClick={() => onConfirm(display)}
          selected={_id === current}
        >
          <span>{name}</span>
          <small>{environmentsNames.join(', ')}</small>
        </DisplayOption>
      );
    },
    [current, onConfirm]
  );

  return (
    <Container>
      <div>{displays.map(renderDisplayOption)}</div>
    </Container>
  );
};
