import {
  CSSProperties,
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';

import { IoClose } from 'react-icons/io5';
import ReactDOM from 'react-dom';

import { Tooltip } from '@chakra-ui/react';

import { useIsMounted } from '~/shared/hooks/useIsMounted';
import { useConfirmationModal } from '~/shared/hooks/useConfirmationModal';
import { useClickOutside } from '~/shared/hooks/useClickOutside';

import { Container, Content, Header, ModalSize } from './styles';

export type OnCloseModal = (
  event?: MouseEvent<HTMLButtonElement, MouseEvent>
) => void;

export interface ModalProps {
  onClose?: OnCloseModal;
  contentStyle?: CSSProperties;
  headerStyle?: CSSProperties;
  backdropStyle?: CSSProperties;
  title?: string;
  size?: ModalSize;
  confirmationText?: string;
  contentId?: string;
  backdropId?: string;
  shouldConfirm?: boolean;
  renderInPortal?: boolean;
  closeOnClickOutside?: boolean;
  showHeader?: boolean;
  closeOnEsc?: boolean;
  closeOnX?: boolean;
}

export function closeModal(
  onClose?: OnCloseModal,
  contentId = 'modal-content',
  backdropId = 'modal-backdrop'
): void {
  const modalContent = document.getElementById(contentId);
  const modalBackdrop = document.getElementById(backdropId);

  if (modalContent && modalBackdrop) {
    modalContent.style.transform = 'translateY(1000px)';
    modalBackdrop.style.opacity = '0';
  }

  setTimeout(() => {
    onClose && onClose();
  }, 300);
}

export const Modal: FC<ModalProps> = ({
  onClose,
  children,
  backdropStyle,
  contentStyle,
  headerStyle,
  shouldConfirm,
  confirmationText = 'deseja realmente sair? suas alterações serão descartadas.',
  size = 'md',
  title,
  renderInPortal = true,
  closeOnClickOutside = true,
  contentId = 'modal-content',
  backdropId = 'modal-backdrop',
  showHeader = true,
  closeOnEsc = true,
  closeOnX = true,
}) => {
  const { open: openConfirmationModal } = useConfirmationModal();

  const backdropRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const isMounted = useIsMounted();

  const handleClose = useCallback(() => {
    if (shouldConfirm) {
      openConfirmationModal({
        content: confirmationText,
        onConfirm: () => closeModal(onClose, contentId, backdropId),
      });

      return;
    }

    closeModal(onClose, contentId, backdropId);
  }, [
    backdropId,
    confirmationText,
    contentId,
    onClose,
    openConfirmationModal,
    shouldConfirm,
  ]);

  const modalContentRef = useClickOutside<HTMLDivElement>(
    handleClose,
    closeOnClickOutside
  );

  useEffect(() => {
    const closeWithEsc = (event: any): void => {
      if (closeOnEsc) {
        if (event.keyCode === 27) {
          handleClose();
        }
      }
    };

    document.addEventListener('keydown', closeWithEsc, false);

    return () => {
      document.removeEventListener('keydown', closeWithEsc, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Element = useMemo(() => {
    return (
      <Container ref={backdropRef} style={backdropStyle} id={backdropId}>
        <Content ref={contentRef} size={size} id={contentId}>
          <div>
            <div ref={modalContentRef} style={contentStyle}>
              {showHeader && (
                <Header style={headerStyle}>
                  <h1>{title}</h1>

                  {closeOnX && (
                    <Tooltip label="Fechar" hasArrow>
                      <button type="button" onClick={handleClose}>
                        <IoClose />
                      </button>
                    </Tooltip>
                  )}
                </Header>
              )}

              {children}
            </div>
          </div>
        </Content>
      </Container>
    );
  }, [
    backdropId,
    backdropStyle,
    children,
    contentId,
    contentStyle,
    handleClose,
    headerStyle,
    modalContentRef,
    showHeader,
    size,
    title,
  ]);

  if (!isMounted) return null;

  if (!renderInPortal) return Element;

  return ReactDOM.createPortal(Element, document.getElementById('modal-root'));
};
