import { useCallback } from 'react';

import { useToast as useChakraToast } from '@chakra-ui/react';

export interface IShowToastData {
  id?: string;
  title: string;
  description?: string;
  variant?: 'warning' | 'error' | 'success' | 'info';
  duration?: number;
  onCloseComplete?: () => void;
}

interface IUseToastResponse {
  show(data: IShowToastData): void;
  update(id: string, data: IShowToastData): void;
}

export const useToast = (): IUseToastResponse => {
  const chakraToast = useChakraToast();

  const show = useCallback(
    (data: IShowToastData) => {
      const tag = data.id
        ? data.id
        : `${data.title}${data.description ? `-${data.description}` : ''}`;

      const isToastActive = chakraToast.isActive(tag);

      if (isToastActive) return;

      chakraToast({
        isClosable: true,
        position: 'top-right',
        id: tag,
        title: data.title,
        status: data.variant,
        description: data.description,
        duration: data.duration || 4000,
        onCloseComplete: data.onCloseComplete,
      });
    },
    [chakraToast]
  );

  const update = useCallback(
    (id: string, data: IShowToastData) => {
      const isToastActive = chakraToast.isActive(id);

      if (isToastActive) {
        chakraToast.update(id, {
          isClosable: true,
          position: 'top-right',
          title: data.title,
          status: data.variant,
          description: data.description,
          duration: data.duration,
        });
      } else {
        show({ ...data, id });
      }
    },
    [show, chakraToast]
  );

  return { show, update };
};
