import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  > div:first-of-type {
    border: 1px solid rgb(230, 230, 230);

    > div:not(:last-of-type) {
      border-bottom: 1px solid rgb(230, 230, 230);
    }
  }
`;

interface IDisplayOptionProps {
  readonly selected: boolean;
}
export const DisplayOption = styled.div<IDisplayOptionProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  height: 60px;
  padding: ${({ theme }) => theme.spacing.xs};
  transition: 0.3s;
  ${({ selected }) =>
    selected &&
    css`
      background-color: ${({ theme }) => theme.colors.primary};
      color: ${({ theme }) => theme.colors.onPrimary};
    `}

  :hover {
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.onPrimary};
  }

  > span {
    font-weight: 600;
  }
`;
