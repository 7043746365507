import { FC, createElement } from 'react';

import { IModalConfig, IModalProps } from '~/shared/hooks/useModal';
import { closeModal, Modal } from '~/shared/components/Modal';

type TModal = {
  modal?: IModalProps;
  handleClose: () => void;
};

export const ModalRoot: FC<TModal> = ({ modal, handleClose }) => {
  if (!modal) return null;
  const { component, config } = modal || {};
  const { onClose, modalProps, props } = config || {};

  function handleOnClose(...params: any): void {
    closeModal(() => handleClose());
    if (onClose) onClose(...params);
  }

  return (
    <Modal onClose={handleClose} {...modalProps}>
      {!!component &&
        createElement<IModalConfig>(component, {
          onClose: onClose ? handleOnClose : null,
          handleClose,
          ...(props || {}),
        })}
    </Modal>
  );
};
